import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { TypeNavigation } from '../navigation/Navigation';
import Typography from '../ui/typography/Typography';

interface Props {
	title?: string;
	list: TypeNavigation[];
}

const FooterNavigation = ({ title, list }: Props) => {
	const {
		t,
		i18n: { language }
	} = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const isSelected = list.find((nav) => nav.to === location.pathname);

	return (
		<>
			{title && <Typography variant="h5">{title}</Typography>}
			<List disablePadding>
				{list.map((item) => (
					<ListItemButton
						key={item.title}
						onClick={() => navigate(`/${language}${item.to}`)}
						selected={isSelected?.to === item.to ?? false}
						disableGutters
						disableRipple
						disableTouchRipple
						dense
					>
						<ListItemIcon>
							<ChevronRightIcon />
						</ListItemIcon>
						<ListItemText primary={t(item.title)} sx={{ margin: 0 }} />
					</ListItemButton>
				))}
			</List>
		</>
	);
};

export default FooterNavigation;
