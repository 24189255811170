/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

const sorting = (array, property) =>
	array.sort((a: { [x: string]: number }, b: { [x: string]: number }) => {
		if (a[property] < b[property]) {
			return -1;
		}
		if (a[property] > b[property]) {
			return 1;
		}
		return 0;
	});

export default sorting;
