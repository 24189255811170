import { useEffect, useState } from 'react';

type ContactData = Record<string, string>;

const contactData: ContactData = {
	id: '1de1c447-cf32-4f62-89fd-8f0dac7d1d73',
	name: 'OC Hypka',
	address: 'Mlynská 2',
	zip: '085 01',
	city: 'Bardejov',
	phone: '+421 919 020 055',
	email: 'info@ochypka.sk',
	map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.0571946740715!2d21.264510476962823!3d49.29425966993377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473e8113b09533bb%3A0x4452d4cf87426e1e!2sOC%20HYPKA!5e0!3m2!1ssk!2ssk!4v1698441011863!5m2!1ssk!2ssk',
	ico: '35712082',
	dic: '2020229827',
	icDph: 'SK2020229827',
	description: 'contact:content'
};

const useContact = () => {
	const [contact, setContact] = useState<ContactData | null>(null);

	useEffect(() => {
		setContact(contactData);
	}, []);

	return {
		contact
	};
};

export default useContact;
