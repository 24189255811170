import { useMediaQuery, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import useTranslate from '../translation/useTranslate';
import Grid from '../ui/grid/Grid';
import Typography from '../ui/typography/Typography';
import OpeningHours from './OpeningHours';

const WelcomeContent = () => {
	const t = useTranslate('welcomeContent');
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid container gap={5} justifyContent="space-between">
			<Grid item xs={12} md={7} p={isMobile ? 1 : 5}>
				<Stack spacing={3}>
					<Typography variant="h1">{t('title')}</Typography>
					<Typography>{t('description')}</Typography>
				</Stack>
			</Grid>
			<Grid item xs={12} md={4} p={isMobile ? 1 : 5}>
				<OpeningHours />
			</Grid>
		</Grid>
	);
};

export default WelcomeContent;
