import { Stack, useMediaQuery, useTheme } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Grid from '../ui/grid/Grid';

const HomeBanner = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const {
		i18n: { language }
	} = useTranslation();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const PRIMARY_IMAGE_HEIGHT = 680;
	const SECONDARY_IMAGE_HEIGHT = 680 / 2 - parseInt(theme.spacing(1), 10);
	const IMAGE_BORDER_RADIUS = 2;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={8}>
				<CardMedia
					component="img"
					alt="primary image"
					title="primary image"
					height={isMobile ? '100%' : PRIMARY_IMAGE_HEIGHT}
					image="/assets/slide3.webp"
					sx={{ borderRadius: IMAGE_BORDER_RADIUS }}
				/>
			</Grid>
			{!isMobile && (
				<Grid item xs={12} md={4}>
					<Stack spacing={2}>
						<CardMedia
							component="img"
							alt="secondary image"
							title="secondary image"
							height={isMobile ? '100%' : SECONDARY_IMAGE_HEIGHT}
							image="/assets/sandal/banner_500_350.webp"
							sx={{ borderRadius: IMAGE_BORDER_RADIUS, cursor: 'pointer' }}
							onClick={() => navigate(`/${language}/shops/shop-sandal`)}
						/>
						<CardMedia
							component="img"
							alt="secondary image"
							title="secondary image"
							height={isMobile ? '100%' : SECONDARY_IMAGE_HEIGHT}
							image="/assets/pepito/banner_500_300.webp"
							sx={{ borderRadius: IMAGE_BORDER_RADIUS, cursor: 'pointer' }}
							onClick={() => navigate(`/${language}/shops/shop-pepito`)}
						/>
					</Stack>
				</Grid>
			)}
		</Grid>
	);
};

export default HomeBanner;
