import '@fontsource/open-sans';
import { ComponentsProps, ComponentsVariants } from '@mui/material/styles';

const MuiTypography: {
	defaultProps: ComponentsProps['MuiTypography'];
	variants: ComponentsVariants['MuiTypography'];
} = {
	defaultProps: {
		style: {
			background: 'transparent',
			boxShadow: 'none'
			//	color: palette?.text?.primary
		}
	},
	variants: [
		{
			props: { sx: { fontWeight: 900 } },
			style: {
				color: 'red !important'
			}
		}
	]
};

export default MuiTypography;
