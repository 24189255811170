import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Params } from 'react-router-dom';
import codelist, { IContent } from '../../../codelist/content';
import HeaderPage from '../../page/HeaderPage';
import Container from '../../ui/container/Container';
import Content from '../article/Content';
import RentingSpacesTable from './RentingSpacesTable';

interface Props {
	params: Readonly<Params<string>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cms: any;
}

const Page = ({ cms, params }: Props) => {
	const { slug, articleId } = params as { slug: string; articleId: string };
	const {
		i18n: { language }
	} = useTranslation();

	useEffect(() => {
		cms.getPage(slug, articleId);

		return () => {
			cms.removePage();
		};
	}, [slug, articleId]);

	if (!cms.page) {
		return <Container>No content</Container>;
	}

	const code = cms.page.content as string;
	const content = codelist[code] as IContent;

	return (
		<Container maxWidth="md" sx={{ mb: 8 }}>
			<Stack spacing={2} mt={4} minHeight={600} alignItems="center">
				<HeaderPage
					title={content.title[language]}
					description={content.description && content.description[language]}
				/>
				<Content data={content.content[language]} />

				{code === 'rentingSpaces' && <RentingSpacesTable />}
			</Stack>
		</Container>
	);
};

export default Page;
