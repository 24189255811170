import { styled } from '@mui/material';
import { ReactNode } from 'react';
import { Link as Lnk } from 'react-router-dom';
import palette from '../../../theme/palette';
import Typography from '../typography/Typography';

const StyledComponent = styled(Typography)({
	'& a': {
		fontFamily: 'Montserrat',
		color: palette?.text?.primary,
		fontSize: '1.0rem',
		textDecoration: 'none'
	}
});

interface Props {
	to: string;
	children: string | ReactNode;
	// eslint-disable-next-line react/no-unused-prop-types
	selected?: boolean;
	target?: string;
	title: string;
	style?: Record<string, unknown>;
}

const Link = ({ to, children, target, title, style }: Props) => (
	<StyledComponent variant="body1">
		<Lnk
			to={to}
			target={target}
			title={title}
			style={{
				...style
			}}
		>
			{children}
		</Lnk>
	</StyledComponent>
);

export default Link;
