import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import List from '@mui/material/List';
import parse from 'html-react-parser';
import Linkify from 'linkify-react';
import { Link } from 'react-router-dom';
import Typography from '../../ui/typography/Typography';

interface Props {
	data: string | string[];
}

const Content = ({ data }: Props) => {
	const theme = useTheme();
	const renderLink = ({
		attributes,
		content
	}: {
		attributes: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			[attr: string]: any;
		};
		content: string;
	}) => {
		const { href, ...props } = attributes;
		return (
			<Link
				to={href}
				title={content}
				{...props}
				style={{ color: theme.palette.error.main }}
			>
				{content}
			</Link>
		);
	};

	if (!data) return null;

	if (typeof data === 'string') {
		return (
			<Typography variant="body1">
				<Linkify options={{ render: renderLink }}>{parse(data)}</Linkify>
			</Typography>
		);
	}

	return (
		<>
			<Typography variant="caption" component="div" mb={1}>
				Popis
			</Typography>
			<List dense>
				{data.map((item) => (
					<ListItem disablePadding>
						<ListItemIcon>
							<ChevronRightIcon />
						</ListItemIcon>
						<ListItemText primary={<Linkify>{item}</Linkify>} />
					</ListItem>
				))}
			</List>
		</>
	);
};

export default Content;
