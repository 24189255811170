import { Outlet } from 'react-router-dom';
import Footer from '../footer/Footer';
import AppBar from '../ui/appBar/AppBar';

const Layout = () => (
	<>
		<AppBar />
		<Outlet />
		<Footer />
	</>
);

export default Layout;
