import { IContent } from '../content';

const Career: IContent = {
	title: {
		sk: `Kariéra`,
		en: `Career`,
		de: `Karriere`
	},
	content: {
		sk: `Momentálne nemáme voľne žiadne pracovne pozície`,
		en: `We currently do not have any job vacancies.`,
		de: `Wir haben derzeit keine offenen Stellenangebote.`
	},
	slug: {
		sk: 'kariera',
		en: 'career',
		de: 'karriere'
	}
};

export default Career;
