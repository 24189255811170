import Grid from '../../ui/grid/Grid';
import Typography from '../../ui/typography/Typography';

interface Props {
	data?: Record<string, string>;
}

const BusinessHours = ({ data }: Props) => {
	//  const { t } = useTranslation();

	if (!data) return null;

	return (
		<>
			<Typography variant="caption" component="div" mb={1}>
				Otváracie hodiny
			</Typography>
			<Grid container spacing={0.5} justifyContent="space-between" width="100%">
				<Grid item xs={12} md={6}>
					<Typography variant="body1">Pondelok - Piatok</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography>{data.weekday}</Typography>
				</Grid>

				{data.weekend && (
					<>
						{' '}
						<Grid item xs={12} md={6}>
							<Typography variant="body1">Sobota - Nedela</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography>{data.weekend}</Typography>
						</Grid>
					</>
				)}

				{data.lunchtime && (
					<>
						<Grid item xs={12} md={6}>
							<Typography variant="body1">Obednajšia prestávka</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography>{data.lunchtime}</Typography>
						</Grid>
					</>
				)}

				{data.saturday && (
					<>
						<Grid item xs={12} md={6}>
							<Typography variant="body1">Sobota</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography>{data.saturday}</Typography>
						</Grid>
					</>
				)}

				{data.sunday && (
					<>
						<Grid item xs={12} md={6}>
							<Typography variant="body1">Nedeľa</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography>{data.sunday}</Typography>
						</Grid>
					</>
				)}
			</Grid>
		</>
	);
};

export default BusinessHours;
