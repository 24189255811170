import '@fontsource/open-sans';
import { useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const Logo2 = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Link to="/" title="Logo">
			<img
				src="/assets/logo_white_transparent.webp"
				alt="Logo"
				title="Logo"
				width={isMobile ? 150 : 200}
			/>
		</Link>
	);
};

export default Logo2;
