import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '../ui/box/Box';
import Grid from '../ui/grid/Grid';
import Typography from '../ui/typography/Typography';
import useContact from './useContact';

const StyledComponent = styled(Box)(({ theme }) => ({
	'& .MuiTypography-h3': {
		fontSize: 32,
		fontWeight: 700,
		marginBottom: theme.spacing(4)
	},
	'& .MuiTypography-body1': {
		fontSize: 20,
		fontWeight: 500,
		marginBottom: theme.spacing(0)
	}
}));

const ContactMap = () => {
	const theme = useTheme();
	const { t } = useTranslation();
	const { contact } = useContact();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	if (!contact) return null;

	return (
		<StyledComponent>
			<Typography
				variant="h2"
				textAlign="center"
				textTransform="uppercase"
				mb={4}
			>
				{t(`contact:contactMap.title`)}
			</Typography>
			<Grid container spacing={6} mb={6}>
				<Grid item xs={12} md={6} height={400}>
					<iframe
						width="100%"
						height="100%"
						frameBorder="0"
						title="map"
						marginHeight={0}
						marginWidth={0}
						scrolling="no"
						src={contact.map}
						style={{
							borderRadius: 20,
							filter:
								theme.palette.mode === 'dark'
									? 'grayscale(0.5) opacity(0.7)'
									: 'none'
						}}
					/>
				</Grid>
				{!isMobile && (
					<Grid item xs={12} md={6} textAlign="center">
						<Box mt={10} />
						<Typography variant="h3">{contact.name}</Typography>
						<Typography>{contact.address}</Typography>
						<Typography>{`${contact.zip} ${contact.city}`}</Typography>
						<Box mb={2} />
						<Typography>
							<SmartphoneIcon /> {contact.phone}, <AlternateEmailIcon />{' '}
							{contact.email}{' '}
						</Typography>
					</Grid>
				)}
			</Grid>
		</StyledComponent>
	);
};

export default ContactMap;
