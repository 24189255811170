import Typography from '../../ui/typography/Typography';
import Content from './Content';

interface Props {
	data?: string;
}

const Telephone = ({ data }: Props) => {
	//  const { t } = useTranslation();

	if (!data) return null;

	return (
		<>
			<Typography variant="caption" component="div" mb={1}>
				Telefón
			</Typography>
			<Content data={data} />
		</>
	);
};

export default Telephone;
