import { Params } from 'react-router-dom';
import ShopItem from './article/ShopItem';
import Page from './page/Page';

interface Props {
	params: Readonly<Params<string>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cms: any;
}

const ArticleContainer = ({ cms, params }: Props) => {
	const { slug } = params as { slug: string; articleId: string };

	if (slug === 'shops' || slug === 'partners') {
		return <ShopItem />;
	}

	return <Page cms={cms} params={params} />;
};

export default ArticleContainer;
