import {
	Container,
	Grid,
	styled,
	useMediaQuery,
	useTheme
} from '@mui/material';
import Stack from '@mui/system/Stack';
import { useTranslation } from 'react-i18next';

import { THEME } from '../../theme/theme';
import useCms from '../cms/useCms';
import Logo2 from '../logo/Logo2';
import { NAVIGATION_LIST } from '../navigation/Navigation';
import Box from '../ui/box/Box';
import Divider from '../ui/divider/Divider';
import Link from '../ui/link/Link';
import Typography from '../ui/typography/Typography';
import FooterContact from './FooterContact';
import FooterNavigation from './FooterNavigation';

const StyledComponent = styled(Box)(({ theme }) => ({
	paddingTop: theme.spacing(5),
	paddingBottom: theme.spacing(2),

	'& .MuiTypography-h5': {
		color: 'white',
		marginBottom: theme.spacing(2)
	},

	'& .MuiList-root': {
		'& .MuiListItemButton-root': {
			color: theme.palette.grey['500'],
			padding: 0,
			paddingBottom: theme.spacing(0.3),

			'& .MuiListItemIcon-root': {
				minWidth: 20
			},

			'& .MuiSvgIcon-root': {
				fontSize: 18
			}
		},

		'& .MuiListItem-root': {
			'& .MuiListItemIcon-root': {
				minWidth: 24
			},
			'& .MuiSvgIcon-root': {
				//	fontSize: 14
			}
		},

		'& .MuiListItemText-root': {
			marginTop: 0,
			marginBottom: theme.spacing(0.5)
		},

		'& .MuiTypography-root': {
			// fontSize: 14
		},

		'& .MuiListItemIcon-root': {
			color: theme.palette.grey['500']
		},

		'& .MuiTypography-body1': {},
		'& .MuiTypography-body2': {
			color: theme.palette.grey['500']
		}
	},

	'& .copyright': {
		'& .MuiTypography-body1': {
			color: theme.palette.grey['500']
		},
		'& a': {
			color: theme.palette.grey['500']
		}
	}
}));

const Footer = () => {
	const theme = useTheme();
	const {
		t,
		i18n: { language }
	} = useTranslation();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const { getCategoryList, getNavigationList } = useCms();

	return (
		<StyledComponent sx={{ background: theme.palette.primary.main }}>
			<Container maxWidth={THEME.maxWidth}>
				<Grid container gap={!isMobile ? 0 : 4} mx={2}>
					<Grid item xs={12} sm={3}>
						<Logo2 />
					</Grid>
					<Grid item xs={12} sm>
						<FooterContact />
					</Grid>
					<Grid item xs={12} sm>
						<FooterNavigation
							title={t(`navigation:informations`)}
							list={getNavigationList(NAVIGATION_LIST)}
						/>
					</Grid>
					<Grid item xs={12} sm>
						<FooterNavigation
							title={t(`cms:category.for-customers.title`)}
							list={getCategoryList('for-customers')}
						/>
					</Grid>
					<Grid item xs={12} sm>
						<FooterNavigation
							title={t(`cms:category.for-partners.title`)}
							list={getCategoryList('for-partners')}
						/>
					</Grid>
				</Grid>
			</Container>
			<Divider sx={{ background: 'white', my: 2, opacity: 0.2 }} />
			<Container className="copyright" maxWidth={THEME.maxWidth}>
				<Stack
					direction={isMobile ? 'column' : 'row'}
					alignItems="center"
					justifyContent="space-between"
					spacing={0.5}
				>
					<Stack direction="row" spacing={1}>
						<Typography>
							&copy; {new Date().getFullYear()} OC Hypka s.r.o.
						</Typography>
						<Typography>|</Typography>
						<Link
							to={`/${language}/cookie`}
							title="Cookie policy"
							style={{ textDecoration: 'none' }}
						>
							Cookie
						</Link>
					</Stack>

					<Link
						to="https://www.weblab.ltd"
						title="Made by Weblab"
						target="_blank"
						style={{ textDecoration: 'none' }}
					>
						<>
							Made by <strong>WebLab</strong>
						</>
					</Link>
				</Stack>
			</Container>
		</StyledComponent>
	);
};

export default Footer;
