import i18next from 'i18next';
import { useState } from 'react';
import codelist from '../../codelist/content';
import filterByUuid from '../../lib/filterByUuid';
import Pathnames from '../../routes/pathnames';
import { TypeNavigation } from '../navigation/Navigation';

export interface Category {
	id: string | number;
	title: string;
	description?: string;
	content?: string | string[];
	slug: string;
	items?: Article[];
	to: string;
	type: 'category' | 'page';
}

export interface Article {
	id: string | number;
	title?: string;
	description?: string;
	content: string | string[];
	category?: 'shops' | 'service' | 'partners';
	slug: string | unknown;
	to: string;
	logo?: string;
	image?: string;
	tel?: string;
	email?: string;
	web?: string;
	instagram?: string;
	facebook?: string;
	address?: string;
	businessHours?: {
		weekday: string;
		weekend?: string;
		lunchtime?: string;
		saturday?: string;
		sunday?: string;
	};
	order?: number;
}

const useCms = () => {
	const [category, setCategory] = useState<Category | null>(null);
	const [article, setArticle] = useState<Article | null>(null);
	const [page, setPage] = useState<Article | Category | null>(null);

	const categories: Category[] = [
		{
			id: 'a3310469-6001-4cfb-a26a-8f57767655d2',
			title: 'cms:category.shops.title',
			description: 'cms:category.shops.description',
			slug: 'shops',
			to: `/shops`,
			type: 'category',
			items: [
				{
					id: 0,
					title: 'Lidl',
					description:
						'Potraviny dennej spotreby v najvyššej kvalite a najlepšie ceny',
					content:
						'Lidl je nemecká maloobchodná sieť, ktorá pôsobí vo viacerých štátoch Európy. Zaoberá sa predajom hlavne potravinárskeho a spotrebného tovaru. V Európe je najväčším a na svete druhým najväčším (po Aldi) diskontným obchodným reťazcom.',
					logo: `/assets/logo_lidl.webp`,
					category: 'shops',
					slug: 'shop-lidl',
					to: `/shops/shop-lidl`,
					web: 'https://www.lidl.sk',
					tel: '0800 005 095',
					order: 1,
					businessHours: {
						weekday: '07:00 - 21:00',
						saturday: '07:00 - 21:00',
						sunday: '08:00 - 20:00'
					}
				},
				{
					id: 1,
					title: 'Kadernícky Salón Majka',
					description: 'Dámske, pánske a detské kaderníctvo',
					content: `V ponuke: spoločenské účesy, pletenie vrkočov, úprava brady, poradensto v oblasti vlasov a ich starostlivosti, možnosť zakúpiť si prípravky zo salónu`,
					category: 'shops',
					slug: 'kadernicy-salon-majka',
					to: `/shops/kadernicy-salon-majka`,
					logo: '/assets/logo_salon_majka.webp',
					order: 3,
					businessHours: {
						weekday: '08:00 - 17:00',
						weekend: 'Objednávky',
						lunchtime: '11:00 - 12:00'
					}
				},
				{
					id: 2,
					title: 'Pepco',
					description:
						'Európsky maloobchodný reťazec s oblečením pre celú rodinu za nízke ceny',
					content: `
					Naša spoločnosť začala pôsobiť v Poľsku pred viac než dvadsiatimi rokmi. Dnes pôsobíme vo viac než dvanástich európskych štátoch. 
					
					Súčasne sme sa stali jedným z najpopulárnejších predajcov detského oblečenia a vedúcim dodávateľom dekoračných tovarov. Za náš úspech vďačíme ľuďom a ich nasadeniu..
					
					V centre nášho záujmu je predovšetkým zákazník. Rozvíjame sa tým, že reagujeme na jeho očakávania a potreby. Našou najväčšou prednosťou je široká ponuka výrobkov za dostupné ceny, útulné obchody a milá obsluha.
					`,
					logo: `/assets/logo_pepco.webp`,
					category: 'shops',
					slug: 'shop-pepco',
					to: `/shops/shop-pepco`,
					order: 2,
					web: 'https://pepco.sk/',
					businessHours: {
						weekday: '08:00 - 20:00',
						saturday: '08:00 - 20:00',
						sunday: '09:00 - 20:00'
					}
				},
				{
					id: 10,
					title: 'Orange',
					description: 'Najväčší mobilný operátor na Slovensku',
					content: `Na domácom trhu, na ktorom pôsobíme už viac ako 20 rokov, sme si vybudovali stabilnú pozíciu lídra, ktorého majú radi takmer 3 milióny zákazníkov.
					
					Pracovať pre značku Orange tiež znamená zaradiť sa do celosvetovej rodiny mobilných operátorov. S 269 miliónmi zákazníkov je skupina Orange popredným svetovým telekomunikačným operátorom.`,
					category: 'shops',
					slug: 'orange',
					to: `/shops/orange`,
					logo: '/assets/orange/logo_orange.webp',
					order: 3,
					businessHours: {
						weekday: '08:30 - 17:00',
						weekend: 'Zatvorené'
					},
					web: 'https://www.orange.sk/'
				},
				{
					id: 6,
					title: 'Pepito',
					description: 'Predajňa výživových doplnkov, vitamínov a minerálov',
					content: `V našej predajni Vám ponúkame výživové doplnky, vitamíny, minerály. Neodmysliteľnou súčasťou sú obľúbené ČERSTVÉ šťavy a smoothie. Nestíhate raňajky? U nás ich nájdete. Vždy chutné a zdravé. `,
					logo: `/assets/logo_pepito.webp`,
					image: `/assets/pepito/img1.webp`,
					category: 'shops',
					slug: 'shop-pepito',
					to: `/shops/shop-pepito`,
					order: 4,
					web: 'https://www.pepito.sk',
					instagram: 'https://instagram.com/pepito.sk',
					facebook: 'https://www.facebook.com/Pepito.sk',
					businessHours: {
						weekday: '07:30 - 18:00',
						saturday: '08:00 - 12:00',
						sunday: 'Zatvorené'
					}
				},
				{
					id: 7,
					title: 'Pekáreň Šandal',
					description: 'Pekáreň Šandal pôsobí na slovenskom trhu od roku 1998',
					content: `Hlavnou činnosťou firmy je pekárenská a cukrárenská výroba, výroba v podobe miestnych špecialít. 
					
					V sieti vlastnych predajni si zákazník môže vybrať zo širokej ponuky výrobkov denne dovážaných zo sídla pekárne a z výrobkov vypekaných na predajni z čerstvých chladených polotovarov z vlastnej produkcie. 
					
					Predajňa Pekáreň Šandal v OC Hypka ponúka možnosť konzumácie na mieste, spolu s ponukou kávy, čaju a iných nealkoholických nápojov. Kvalitná káva a zákusok z vlastnej produkcie je to najlepšie ako si spríjemniť deň. 
					
					A to všetko len pár krokov z parkoviska, kde je bezplatné parkovanie počas hodiny a pol. `,
					logo: `/assets/sandal/logo_sandal.webp`,
					image: `/assets/sandal/image1.webp`,
					category: 'shops',
					slug: 'shop-sandal',
					to: `/shops/shop-sandal`,
					order: 4,
					web: 'https://www.pekarensandal.sk',
					instagram: 'https://www.instagram.com/pekarensandal',
					facebook: 'https://www.facebook.com/profile.php?id=100064074311810',
					businessHours: {
						weekday: '06:30 - 17:30',
						saturday: '07:00 - 16:00',
						sunday: 'Zatvorené'
					}
				},
				{
					id: 8,
					title: 'Luxury Sun',
					description: 'Hybridné Solárne Štúdio',
					content: `Solárne štúdio Luxury Sun v spolupráci s be brown® nie je vo svete solárií žiadnym nováčikom, no napriek tomu sa drží na vlne noviniek. 
					
					Tou sú aj hybridné soláriá. Čím sú iné? Hybridné solária pri opaľovaní podporujú aj tvorbu kolagénu. 
					
					Bez vysokých dávok UV žiarenia dosiahnete nielen krásne opálenie, ale vďaka kolagénovým trubiciam, ktoré využívajú viditeľné červené svetlo, 
					bude vaša pleť a pokožka napätá a hladká. Hybridné soláriá totiž stimulujú aj tvorbu kolagénu a elastínu.`,
					logo: `/assets/luxury_sun/logo_luxurysun.webp`,
					image: `/assets/luxury_sun/image1.webp`,
					category: 'shops',
					slug: 'shop-luxury-sun',
					to: `/shops/shop-luxury-sun`,
					order: 4,
					web: 'https://www.luxurysun.sk',
					facebook: 'https://www.facebook.com/luxurysunbardejov',
					tel: '0911 808 384',
					email: 'luxurysunbardejov@gmail.com',
					businessHours: {
						weekday: '09:00 - 19:00',
						saturday: '09:00 - 19:00',
						sunday: 'Zatvorené'
					}
				},
				{
					id: 9,
					title: 'Lekáreň Dr.Max',
					description: 'Líder na trhu lekární v Slovenskej republike',
					content: `Cieľom lekární Dr. Max je priniesť zákazníkom kvalitné služby a odborné poradenstvo kedykoľvek to budú potrebovať. Ročne ich na Slovensku navštívi viac ako 15 miliónov zákazníkov. Značka Dr. Max tak pre nás predstavuje veľký reputačný záväzok a zodpovednosť.
	
					V lekárňach vychádzame zákazníkom v ústrety nielen dostupnou lokalitou, prehľadným usporiadaním sortimentu, ale aj pestrou ponukou. Okrem produktov od partnerov u nás dnes zákazníci nájdu už viac ako 270 rôznych produktov aj pod značkou Dr. Max. Toto portfólio pokrýva najrôznejšie kategórie od výživových doplnkov cez zdravotnícke pomôcky a kozmetické výrobky až po lieky. V rámci dostupnosti liekov i voľnopredajných produktov sa snažíme vždy robiť pre pacientov maximum.
					
					Starostlivosť o zákazníka prinášame cez náš personál v lekárňach aj pravidelnými edukačnými kampaňami pre verejnosť s cieľom posilniť prevenciu a zdravý životný štýl. V roku 2016 sa týchto kampaní zúčastnilo a bezplatné odborné poradenstvo lekárnikov využilo viac ako 31 000 Slovákov.`,
					logo: `/assets/drmax/logo_drmax.webp`,
					//	image: `/assets/luxury_sun/image1.webp`,
					category: 'shops',
					slug: 'shop-drmax',
					to: `/shops/shop-drmax`,
					order: 4,
					web: 'https://www.drmax.sk',
					facebook: 'https://www.facebook.com/drmaxlekaren',
					instagram: 'https://www.instagram.com/lekaren_drmax',
					//	tel: '0911 808 384',
					//	email: 'luxurysunbardejov@gmail.com',
					businessHours: {
						weekday: '08:00 - 20:00',
						weekend: '08:00 - 20:00'
					}
				},
				{
					id: 14,
					title: 'Caffe Ragazza',
					description: 'Ponúkame kvalitnú kávu, drinky, limonády',
					content: `Kaviareň s 19. ročnou históriou. Pripravíme Vám najlepšiu šálku kávy podľa Vášho výberu spolu s dezertom.`,
					logo: `/assets/ragazza/logo_ragazza.webp`,
					category: 'shops',
					slug: 'shop-ragazza',
					to: `/shops/shop-ragazza`,
					order: 4,
					facebook:
						'https://www.facebook.com/p/Caff%C3%A9-Ragazza-Bardejov-100064320363714',
					instagram: 'https://www.instagram.com/caffe.ragazza'
					/* businessHours: {
						weekday: '08:00 - 20:00',
						weekend: '08:00 - 20:00'
					} */
				},
				{
					id: 15,
					title: 'Geco',
					description:
						'Ponúkame tabakové výrobky, lotériové produkty, tlač a telefónne karty',
					content: `Na Slovensku pôsobíme od roku 2000 a v súčasnosti prevádzkujeme viac ako 55 maloobchodných predajní typu "TABAK - TLAČ".`,
					logo: `/assets/logo_geco.webp`,
					image: `/assets/logo_geco.webp`,
					category: 'shops',
					slug: 'shop-geco',
					to: `/shops/shop-geco`,
					order: 4,
					web: 'https://www.geco.sk/',
					facebook: 'https://www.facebook.com/profile.php?id=100063626598506'
					/* businessHours: {
						weekday: '08:00 - 20:00',
						weekend: '08:00 - 20:00'
					} */
				}
			]
		},
		{
			id: 'a3310469-6001-4cfb-a26a-8f57767655d3',
			title: 'cms:category.partners.title',
			description: 'cms:category.partners.description',
			slug: 'partners',
			to: `/partners`,
			type: 'category',
			items: [
				{
					id: 3,
					title: 'Flameshoes',
					description: 'Výrobca plastovej obuvi z EVA materiálu',
					content: [
						'EVA materiál je veľmi mäkký pružný a pevný',
						'slovenský výrobok',
						'vyrába oddychovú a voľnočasovú obuv, čižmy, zateplenú obuv',
						'ponúka rôznorodosť dizajnu a farebných kombinácií',
						'export tovaru do okolitých európskych krajín'
					],
					logo: `/assets/logo_flameshoes.webp`,
					category: 'partners',
					slug: 'partner-flameshoes',
					to: `/partners/partner-flameshoes`,
					order: 3,
					web: 'https://www.flameshoes.sk',
					email: 'flameshoes@flameshoes.sk',
					address: 'Kružlov 292, 086 04 Kružlov'
				},
				{
					id: 4,
					title: 'Pavličko a partneri, s.r.o',
					description:
						'Pavličko a partneri, s.r.o, Ranč Regetovka a Ranč Regetovka Javorina',
					content: [
						'Stavebný úsek: prenájom plošiny a techniky, žeriavnické práce, zemné a výkopové práce, dovoz materiálu a kameniva, realizácia stavieb, striekané izolácie a farby',
						'slovenský výrobok',
						'Reštauračno-hoteliersky a rekreačný úsek ( https://regetovka.sk ): rekreačný komplex zahŕňajúci reštauráciu, ubytovanie, lyžiarske stredisko nachádzajúci sa v malebnej obci Regetovka, len 17 km od historického mesta Bardejov – svetového dedičstva UNESCO.',
						'Naše produkty nájdete na parkovisku pri OC HYPKA Bardejov.'
					],
					logo: `/assets/logo_pavlicko.webp`,
					category: 'partners',
					slug: 'partner-pavlicko',
					to: `/partners/partner-pavlicko`,
					order: 4,
					web: 'https://www.pavlickoapartneri.sk',
					email: 'stavby@pavlickoapartneri.sk'
				},
				{
					id: 5,
					title: 'Print Cup',
					description:
						'Výroba ekologických a recyklovateľných papierových pohárov a slamiek',
					content: `Sme slovenská spoločnosť Print Cup s.r.o, ktorá je na trhu od roku 2019 so sídlom v Kružlove. Hlavnou činnosťou našej spoločnosti je výroba kompostovateľných a recyklovateľných papierových pohárov a slamiek. Všetky výrobky vyrábame s ohľadom na životné prostredie, čím sa snažíme chrániť prírodu a prispievať tak k ekologickému rastu planéty. 
	
					Našou prioritou je výroba produktov z kvalitných a certifikovaných materiálov, všetky materiály, ktoré používame na výrobu našich produktov majú certifikát FSC. `,
					logo: `/assets/logo_print_cup.webp`,
					category: 'partners',
					slug: 'partner-printcup',
					to: `/partners/partner-printcup`,
					order: 4,
					web: 'https://www.printcup.sk',
					email: 'obchod@printcup.sk'
				},
				{
					id: 7,
					title: 'Unizdrav',
					description: 'Internetový obchod',
					content: `Internetový obchod www.unizdrav.sk vznikol v roku 2010 na základe licencie č. KL/1769/2010 vydanej Štátnym ústavom pre kontrolu liečiv. V obchode sa vtedy nachádzal základný sortiment zdravotníckych pomôcok. Odvtedy sa veľa zmenilo.
					
					V dnešnej dobe sa na e-shope nachádza viac ako 3000 produktov zdravotných pomôcok, prístrojov, zdravotnej obuvi, doplnkového sortimentu a mnoho iného. To ale nie je všetko, pretože každý deň pracuje tím ľudí na tom, aby sa sortiment neustále rozširoval o nové produkty, ktoré sú žiadané zákazníkmi. Častokrát ide aj o produkty, ktoré nie sú až tak dostupné. Veľa fotografií a videí vytvárame sami, aby sme vám opísali produkt čo najpresnejšie.
					
					Okrem produktov neustále pracujeme aj na prístupe k zákazníkom. Snažíme sa, aby bol nákup jednoduchý, pohodlný a vyhovoval každému zákazníkovi. Dbáme na to, aby bola zodpovedaná každá vaša otázka, či už mailom alebo telefonikcky.
					
					To všetko sme dosiahli len vďaka vám - zákazníkom. Veríme, že s nákupom u nás budete maximálne spokojní, pretože spokojní zákazníci nás motivujú k tomu, aby sme pre vás urobili maximum.`,
					logo: `/assets/unizdrav/logo_unizdrav.webp`,
					category: 'partners',
					slug: 'partner-unizdrav',
					to: `/partners/partner-unizdrav`,
					order: 4,
					web: 'https://www.unizdrav.sk'
				}
			]
		},
		{
			id: '601b74bd-3ca8-4b3a-aa09-27357dc0cffa',
			title: 'cms:category.for-customers.title',
			description: 'cms:category.for-customers.description',
			slug: 'for-customers',
			to: `/for-customers`,
			type: 'category',
			items: [
				{
					id: '8f2a1057-ebc6-4f0f-9171-6c2f5e1ef43f',
					title: codelist.businessHours.title[i18next.language],
					content: 'businessHours',
					slug: codelist.businessHours.slug[i18next.language],
					to: `/for-customers/${codelist.businessHours.slug[i18next.language]}`
				},
				{
					id: '5be4a0c0-c37c-4d67-b628-ae804c36a3c8',
					title: codelist.parking.title[i18next.language],
					content: 'parking',
					slug: codelist.parking.slug[i18next.language],
					to: `/for-customers/${codelist.parking.slug[i18next.language]}`
				},
				{
					id: 'a9be8a17-5028-4773-bf6e-aaba4e127f68',
					title: codelist.visitorPolicy.title[i18next.language],
					content: 'visitorPolicy',
					slug: codelist.visitorPolicy.slug[i18next.language],
					to: `/for-customers/${codelist.visitorPolicy.slug[i18next.language]}`
				}
			]
		},
		{
			id: 'af27c9b8-f076-4da1-8bc6-d8cc30917f4d',
			title: 'cms:category.for-partners.title',
			description: 'cms:category.for-partners.description',
			slug: 'for-partners',
			to: '/for-partners',
			type: 'category',
			items: [
				{
					id: 'bed52a6a-c44c-47c0-b61a-6660012dfd38',
					title: codelist.rentingSpaces.title[i18next.language],
					content: 'rentingSpaces',
					slug: codelist.rentingSpaces.slug[i18next.language],
					to: `/for-partners/${codelist.rentingSpaces.slug[i18next.language]}`
				},
				{
					id: '7585c063-13a6-4d91-a961-fd54f1901a80',
					title: codelist.career.title[i18next.language],
					content: 'career',
					slug: codelist.career.slug[i18next.language],
					to: `/for-partners/${codelist.career.slug[i18next.language]}`
				}
			]
		},
		{
			id: '9be10831-092d-45a4-9b42-b3eef80f495b',
			title: 'cms:category.news.title',
			description: 'cms:category.news.description',
			slug: 'news',
			to: `/news`,
			items: [],
			type: 'category'
		},
		{
			id: '1de1c447-cf32-4f62-89fd-8f0dac7d1d73',
			title: 'contact:contact.title',
			description: 'contact:description',
			slug: 'contact',
			to: Pathnames.CONTACT,
			type: 'page',
			items: []
		},
		{
			id: '1de1c447-cf32-4f62-89fd-8f0dac7d1d75',
			title: 'gdpr:title',
			description: 'gdpr:description',
			content: 'cookie',
			slug: 'cookie',
			type: 'page',
			to: '/cookie'
		}
	];

	const getPage = (slug: string, articleId: string) => {
		const group = categories.find((cat: Category) => cat.slug === slug);

		if (group && group.items) {
			const response = group?.items?.find(
				(item: Article) => item.slug === articleId
			);

			setPage(response || null);
		}

		if (group && !group.items) {
			setPage(group);
		}
	};

	const removePage = () => {
		setPage(null);
	};

	const getArticle = (slug: string, articleId: string) => {
		const group = categories.find((cat: Category) => cat.slug === slug);
		const response = group?.items?.find(
			(item: Article) => item.slug === articleId
		);

		setArticle(response || null);
	};

	const removeArticle = () => {
		setArticle(null);
	};

	const getCategory = (slug: string) => {
		const response = categories.find((cat: Category) => cat.slug === slug);

		setCategory(response || null);
	};

	const getCategoryList = (slug: string) => {
		const response = categories.find((cat: Category) => cat.slug === slug);

		return response?.items?.map((item: Article) => ({
			id: item.id,
			title: item.title,
			to: item.to
		})) as TypeNavigation[];
	};

	const getNavigationList = (uuids: string[]): TypeNavigation[] =>
		filterByUuid(categories, uuids);

	return {
		category,
		article,
		page,
		getPage,
		getArticle,
		removeArticle,
		removePage,
		getCategory,
		getCategoryList,
		getNavigationList
	};
};

export default useCms;
