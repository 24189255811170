import {
	AppBar as MuiAppBar,
	Container,
	Stack,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { THEME } from '../../../theme/theme';
import Logo from '../../logo/Logo';
import MobileNavigation from '../../navigation/MobileNavigation';
import Navigation from '../../navigation/Navigation';
import LangSwitcher from '../../translation/LangSwitcher';
import Box from '../box/Box';
import Toolbar from './Toolbar';

const AppBar = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<>
			<MuiAppBar component="nav" position="fixed">
				<Toolbar
					sx={{
						height: 100,
						background: 'white',
						borderBottom: `0px solid ${theme.palette.grey['300']}`
					}}
				>
					<Container disableGutters={false} maxWidth={THEME.maxWidth}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<Logo />
							{!isMobile && (
								<Box
									sx={{
										// height: 100,
										direction: 'row',
										display: 'flex',
										alignItems: 'center',
										gap: 5
									}}
								>
									<Navigation />
									<LangSwitcher />
								</Box>
							)}

							{isMobile && <MobileNavigation />}
						</Stack>
					</Container>
				</Toolbar>
			</MuiAppBar>
			<Box height={100} />
		</>
	);
};

export default AppBar;
