import { PaletteOptions } from '@mui/material';

const palette: PaletteOptions = {
	text: {
		primary: '#282a2b'
	},
	info: {
		main: '#FAAE16'
	},
	primary: {
		main: '#282a2b'
	},
	secondary: {
		main: '#0277e4'
	}
};

export default palette;
