import BusinessHours from './_content/BusinessHours';
import Career from './_content/Career';
import Cookie from './_content/Cookie';
import Parking from './_content/Parking';
import RentingSpaces from './_content/RentingSpaces';
import VisitorPolicy from './_content/VisitorPolicy';

type LanguageObject = {
	[key: string]: string;
};

export interface IContent {
	title: LanguageObject;
	description?: LanguageObject;
	content: LanguageObject;
	slug: LanguageObject;
}

export interface RentingSpacesTablesDataFragment {
	title: Record<string, string>;
	description: Record<string, string>;
	meters: string;
}

const codelist: Record<string, IContent> = {
	visitorPolicy: VisitorPolicy,
	parking: Parking,
	businessHours: BusinessHours,
	rentingSpaces: RentingSpaces,
	career: Career,
	cookie: Cookie
};

export default codelist;
