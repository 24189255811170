import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Card from '../../ui/card/Card';
import Grid from '../../ui/grid/Grid';
import Typography from '../../ui/typography/Typography';
import { Category } from '../useCms';

interface Props {
	children: Category['items'];
}

const List = ({ children }: Props) => (
	<Grid container spacing={4} mt={3}>
		{children?.map((item) => (
			<Grid item xs={12} md={3}>
				<Card elevation={0}>
					<CardMedia image={item.logo} component="img" height={100} />
					<CardContent>
						<Typography variant="h3">{item.title}</Typography>
						<Typography>{item.description}</Typography>
					</CardContent>
				</Card>
			</Grid>
		))}
	</Grid>
);

export default List;
