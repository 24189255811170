import '@fontsource/open-sans';
import { Link } from 'react-router-dom';
import logo from './logo.webp';

const Logo = () => (
	<Link to="/" title="Logo" style={{ display: 'flex' }}>
		<img src={logo} alt="Logo" title="Logo" height={58} />
	</Link>
);

export default Logo;
