import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import de from './de/translation.json';
import en from './en/translation.json';
import sk from './sk/translation.json';

i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: process.env.NODE_ENV !== 'production',
		supportedLngs: ['sk', 'de', 'en'],
		resources: {
			sk,
			de,
			en
		},
		fallbackLng: 'en',
		ns: ['translations'],
		defaultNS: 'translations',
		interpolation: { escapeValue: false },
		react: {
			bindI18n: 'languageChanged',
			bindI18nStore: '',
			transEmptyNodeValue: '',
			transSupportBasicHtmlNodes: true,
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
			useSuspense: false
		}
	});

export default i18next;
