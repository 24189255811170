import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import useContact from '../contact/useContact';
import Box from '../ui/box/Box';
import Typography from '../ui/typography/Typography';

const FooterContact = () => {
	const { contact } = useContact();

	if (!contact) return null;

	return (
		<>
			<Typography variant="h5">{contact.name}</Typography>
			<List disablePadding dense>
				<ListItem disableGutters disablePadding>
					<ListItemText primary={contact.address} />
				</ListItem>
				<ListItem disableGutters disablePadding>
					<ListItemText primary={`${contact.zip} ${contact.city}`} />
				</ListItem>
				<Box mb={2} />
				<ListItem disableGutters disablePadding>
					<ListItemIcon>
						<SmartphoneIcon />
					</ListItemIcon>
					<ListItemText primary={contact.phone} />
				</ListItem>
				<ListItem disableGutters disablePadding>
					<ListItemIcon>
						<AlternateEmailIcon />
					</ListItemIcon>
					<ListItemText primary={contact.email} />
				</ListItem>
			</List>
		</>
	);
};

export default FooterContact;
