import { Suspense } from 'react';
import CmsContainer from '../../components/cms/CmsContainer';
import Contact from '../../components/contact/Contact';
import HomePage from '../../components/home/Home';
import Layout from '../../components/layout';
import {
	getCmsArticleUrl,
	getCmsCategoryUrl,
	getContactPageUrl,
	getHomePageUrl
} from './AppUrls';

const publicRoutes = [
	{
		element: (
			<Suspense fallback={<>...</>}>
				<Layout />
			</Suspense>
		),
		path: (locale: string) => `/${locale}`,
		children: [
			{
				element: <HomePage />,
				path: getHomePageUrl
			},
			{
				element: <Contact />,
				path: getContactPageUrl
			},
			{
				element: <CmsContainer />,
				path: getCmsCategoryUrl
			},
			{
				element: <CmsContainer />,
				path: getCmsArticleUrl
			}
		]
	}
];

const privateRoutes: never[] = [];

const allRoutes = [...privateRoutes, ...publicRoutes];

export default allRoutes;
