import { CardMedia, styled, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { THEME } from '../../../theme/theme';
import Seo from '../../seo/Seo';
import Container from '../../ui/container/Container';
import Divider from '../../ui/divider/Divider';
import Grid from '../../ui/grid/Grid';
import Typography from '../../ui/typography/Typography';
import useCms from '../useCms';
import Address from './Address';
import BusinessHours from './BusinessHours';
import Content from './Content';
import EmailAddress from './EmailAddress';
import Facebook from './Facebook';
import Instagram from './Instagram';
import Telephone from './Telephone';
import WebAddress from './WebAddress';

const StyledComponent = styled(Container)(({ theme }) => ({
	minHeight: 650,
	paddingTop: theme.spacing(1),
	paddingBottom: theme.spacing(12),

	'& .MuiTypography-h1': {
		margin: theme.spacing(0)
	},
	'& .MuiTypography-h2': {
		fontWeight: 400,
		margin: theme.spacing(0),
		opacity: 0.7
	}
}));

const ShopItem = () => {
	const cms = useCms();
	const { t } = useTranslation();
	const theme = useTheme();
	const params = useParams();
	const { slug, articleId } = params as { slug: string; articleId: string };

	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		cms.getArticle(slug, articleId);

		return () => {
			cms.removeArticle();
		};
	}, []);

	if (!cms.article) {
		return <Container>No content</Container>;
	}

	const {
		title,
		description,
		content,
		businessHours,
		image,
		logo,
		web,
		email,
		address,
		instagram,
		facebook,
		tel
	} = cms.article;

	return (
		<StyledComponent maxWidth={THEME.maxWidth}>
			<Seo title={t(title)} description={t(description)} />
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Typography variant="h1">{title}</Typography>
					<Typography variant="subtitle1">{description}</Typography>
					<Divider sx={{ my: 6 }} />
					<Grid container spacing={2}>
						{address && (
							<Grid item xs={12} md={6}>
								<Address data={address} />
							</Grid>
						)}
						{tel && (
							<Grid item xs={12} md={6}>
								<Telephone data={tel} />
							</Grid>
						)}
						{email && (
							<Grid item xs={12} md={6}>
								<EmailAddress data={email} />
							</Grid>
						)}
						{web && (
							<Grid item xs={12} md={6}>
								<WebAddress data={web} />
							</Grid>
						)}
						{instagram && (
							<Grid item xs={12} md={6}>
								<Instagram data={instagram} />
							</Grid>
						)}
						{facebook && (
							<Grid item xs={12} md={6}>
								<Facebook data={facebook} />
							</Grid>
						)}

						{businessHours && (
							<Grid item xs={12} md={6}>
								<BusinessHours data={businessHours} />
							</Grid>
						)}
					</Grid>
					<Divider sx={{ my: 6 }} />
					<Content data={content} />
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					alignItems="center"
					justifyContent="center"
					display="flex"
				>
					{(image && (
						<CardMedia
							component="img"
							alt="secondary image"
							// height="300px"
							image={image}
							sx={{
								borderRadius: theme.shape.borderRadius,
								mx: 0,
								width: '70%'
							}}
						/>
					)) || <img alt="logo" src={logo} />}
				</Grid>
			</Grid>
		</StyledComponent>
	);
};

export default ShopItem;
