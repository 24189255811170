import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Content from '../cms/article/Content';
import HeaderPage from '../page/HeaderPage';
import useTranslate from '../translation/useTranslate';
import Box from '../ui/box/Box';
import Divider from '../ui/divider/Divider';
import Typography from '../ui/typography/Typography';
import GoogleMap from './GoogleMap';
import useContact from './useContact';

const Contact = () => {
	const t = useTranslate('contact');
	const { contact } = useContact();

	if (!contact) return null;

	return (
		<>
			<Container maxWidth="sm" sx={{ mb: 4 }}>
				<HeaderPage title={t('title')} description={t('description')} />
				<Box>
					<Typography variant="caption">{contact.name}</Typography>
					<Typography>{contact.address}</Typography>
					<Typography>{`${contact.zip} ${contact.city}`}</Typography>
				</Box>
				<Divider sx={{ my: 2 }} />
				<Stack direction="row" spacing={2} justifyContent="space-between">
					<Typography width="50%" variant="caption">
						{t('contact.title')}
					</Typography>
					<Typography width="50%">{contact.phone}</Typography>
				</Stack>
				<Divider sx={{ my: 2 }} />
				<Stack direction="row" spacing={2} justifyContent="space-between">
					<Typography width="50%" variant="caption">
						{t('email.title')}
					</Typography>
					<Typography width="50%">
						<Content data={contact.email} />
					</Typography>
				</Stack>
				<Divider sx={{ my: 2 }} />
				<Content data={t('content')} />
			</Container>
			<Container maxWidth="lg" sx={{ mb: 4 }}>
				<GoogleMap />
			</Container>
		</>
	);
};

export default Contact;
