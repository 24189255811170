import { useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Params } from 'react-router-dom';
import { THEME } from '../../theme/theme';
import Seo from '../seo/Seo';
import Typography from '../ui/typography/Typography';
import GridList from './category/GridList';
import List from './category/List';

interface Props {
	params: Readonly<Params<string>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cms: any;
}

const Category = ({ cms, params }: Props) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const { slug } = params as { slug: string };

	useEffect(() => {
		cms.getCategory(slug);
	}, [slug]);

	if (!cms.category) {
		return <Container>No content</Container>;
	}

	const { title, description, items } = cms.category;
	const maxWidth =
		slug === 'news' || slug === 'shops' || slug === 'partners'
			? THEME.maxWidth
			: 'lg';

	return (
		<Container maxWidth={maxWidth} sx={{ paddingBottom: theme.spacing(8) }}>
			<Seo title={t(title)} description={t(description)} />
			<Typography variant="h1">{t(title)}</Typography>
			{/* {description && <Typography variant="h2">{t(description)}</Typography>}
			 */}
			{slug === 'shops' && <GridList>{items}</GridList>}
			{slug === 'partners' && <GridList>{items}</GridList>}
			{slug === 'news' && <GridList>{items}</GridList>}

			{slug !== 'shops' && slug !== 'news' && slug !== 'partners' && (
				<List>{items}</List>
			)}
		</Container>
	);
};

export default Category;
