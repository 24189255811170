import { useTheme } from '@mui/material';
import Link from '../../ui/link/Link';
import Typography from '../../ui/typography/Typography';

interface Props {
	data?: string;
}

const EmailAddress = ({ data }: Props) => {
	const theme = useTheme();
	//  const { t } = useTranslation();

	if (!data) return null;

	return (
		<>
			<Typography variant="caption" component="div" mb={1}>
				Emailová adresa
			</Typography>
			<Link
				to={`mailto:${data}`}
				title={data}
				target="_blank"
				style={{ color: theme.palette.error.main }}
			>
				{data}
			</Link>
		</>
	);
};

export default EmailAddress;
