/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */

import { Category } from '../components/cms/useCms';
import { TypeNavigation } from '../components/navigation/Navigation';

function findObjectsByUuids(
	arr: Category[],
	uuidsToFilter: string[]
): TypeNavigation[] {
	const result: TypeNavigation[] = [];

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function recursiveSearch(obj: any) {
		if (uuidsToFilter.includes(obj.id)) {
			result.push({
				id: obj.id,
				slug: obj.slug,
				to: obj.to,
				title: obj.title
			});
		}

		if (obj.items) {
			for (const child of obj.items) {
				recursiveSearch(child);
			}
		}
	}

	for (const item of arr) {
		recursiveSearch(item);
	}

	return result;
}

const filterByUuid = (data: Category[], uuidsToFilter: string[]) =>
	findObjectsByUuids(data, uuidsToFilter);

export default filterByUuid;
