import { useParams } from 'react-router-dom';
import ArticleContainer from './Article';
import Category from './Category';
import Page from './page/Page';
import useCms from './useCms';

const CmsContainer = () => {
	const params = useParams();
	const cms = useCms();
	const { slug, articleId } = params as { slug: string; articleId: string };

	if (slug && articleId) {
		return <ArticleContainer cms={cms} params={params} />;
	}

	if (slug === 'shops' || slug === 'partners' || slug === 'news') {
		return <Category cms={cms} params={params} />;
	}

	return <Page cms={cms} params={params} />;
};

export default CmsContainer;
