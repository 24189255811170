import { IContent } from '../content';

const VisitorPolicy: IContent = {
	title: {
		sk: `Návštevný poriadok`,
		en: `Visitor's Policy`,
		de: `Besucherrichtlinien`
	},
	content: {
		sk: `Vážení zákazníci,
    dovoľte nám, aby sme Vás v mene Správy centra privítali v OC Hypka. Účelom návštevného poriadku je poskytnúť našim zákazníkom maximálne využitie všetkých nákupných a odpočinkových možností.
    
    Správa centra kontroluje a usmerňuje správanie sa návštevníkov tak, aby nebola narušená plynulá prevádzka centra a ostatní návštevníci neboli rušení či obťažovaní pri pohodlnom nákupe. V prípade porušenia návštevného poriadku sú pracovníci SBS oprávnení zasiahnuť proti porušovateľovi.
    
     1) Poškodzovať alebo ničiť majetok OC Hypka, manipulovať s akýmkoľvek technickým a technologickým
     vybavením a vykonávať akékoľvek činnosti, ktoré ohrozujú bezpečnosť osôb a majetku.
     2) Konzumovať alkohol (s výnimkou priestorov na to určených).
     3) Fajčiť (s výnimkou priestorov na to určených).
     4) Držať a užívať narkotiká, psychotropné a iné omamné látky.
     5) Ležať alebo spať v priestoroch objektu.
     6) Žobrať alebo inak obťažovať návštevníkov za účelom získania daru.
     7) Vnášať predmety ohrozujúce verejnú bezpečnosť, život a zdravie návštevníkov či zamestnancov.
     8) Znečisťovať objekt, pľuť, lepiť žuvačky, odkladať odpadky mimo vyhradených odpadkových košov a pod.
     9) Jazdiť na kolieskových korčuliach, skateboardoch, kolobežkách či iných športovo-zábavných dopravných
     prostriedkoch v priestoroch nákupného centra.
     10) Rušiť alebo inak obťažovať ostatné osoby v objekte svojím prejavom, oblečením, hlukom, zápachom,
     vulgárnym či nevhodným správaním atď.
     11) Vstupovať do akýchkoľvek priestorov určených pre zásobovanie či technickú obsluhu.
     12) Pohybovať sa v objekte mimo otváracích hodín centra okrem tých častí, ktoré sú pre verejnosť prístupné
     bez obmedzenia.
     13) Vykonávať bez súhlasu Správy centra akékoľvek obchodné aktivity na území centra, realizovať reklamné
     akcie, propagácie výrobkov, distribúciu letákov, predávať akýkoľvek tovar alebo služby, ponúkať vzorky,
     vykonávať ankety a prieskumy, prevádzkovať hudobnú či tanečnú produkciu, umiestňovať reklamné
     tabule alebo stojany akéhokoľvek druhu.
     14) Organizovať politické agitácie, petičné akcie, prieskumy verejnej mienky, demonštrácie, propagácie
     akýchkoľvek hnutí a vykonávať finančné zbierky. Povolenie môže udeliť iba Správa centra a zároveň je
     oprávnená stanoviť obmedzujúce opatrenia.
     15) Nechať psy voľne sa pohybovať vo vnútri objektu i na priľahlých vonkajších plochách bez vôdzky/náhubku.`,
		en: `Dear customers,
    Please allow us to welcome you on behalf of the Management of the center to OC Hypka. The purpose of the visitor policy is to provide our customers with maximum utilization of all shopping and relaxation opportunities. 
    
    The Management of the center controls and directs the behavior of visitors to ensure smooth operation of the center and that other visitors are not disturbed or bothered during their comfortable shopping experience. In case of violation of the visitor policy, the SBS staff are authorized to take action against the violator.
    
    1) Damaging or destroying property of OC Hypka, manipulating any technical and technological equipment, and performing activities that endanger the safety of persons and property.
    2) Consuming alcohol (except in designated areas).
    3) Smoking (except in designated areas).
    4) Holding and using narcotics, psychotropic and other intoxicating substances.
    5) Lying or sleeping in the premises of the building.
    6) Begging or otherwise bothering visitors for the purpose of obtaining a gift.
    7) Bringing in objects that endanger public safety, life and health of visitors or employees.
    8) Polluting the building, spitting, sticking chewing gum, leaving garbage outside designated trash bins, etc.
    9) Riding roller skates, skateboards, scooters, or other sports and recreational transport devices in the shopping center premises.
    10) Disturbing or otherwise bothering other people in the building with their behavior, clothing, noise, odor, vulgar or inappropriate behavior, etc.
    11) Entering any areas designated for supply or technical maintenance.
    12) Moving around the building outside the center's opening hours except for those parts that are accessible to the public without restriction.
    13) Conducting any commercial activities on the premises of the center without the consent of the Management, conducting advertising campaigns, product promotions, distribution of leaflets, selling any goods or services, offering samples, conducting surveys and polls, operating musical or dance productions, placing advertising boards or stands of any kind.
    14) Organizing political agitations, petition actions, public opinion surveys, demonstrations, promotions of any movements and conducting financial collections. Permission can only be granted by the Management of the center and it is also authorized to set restrictive measures.
    15) Letting dogs move freely inside the building and in adjacent outdoor areas without a leash/muzzle.`,
		de: `Sehr geehrte Kunden,
    Bitte erlauben Sie uns, Sie im Namen der Verwaltung des Zentrums in OC Hypka willkommen zu heißen. Der Zweck der Besucherordnung ist es, unseren Kunden eine maximale Nutzung aller Einkaufs- und Entspannungsmöglichkeiten zu bieten. 
    
    Die Verwaltung des Zentrums kontrolliert und lenkt das Verhalten der Besucher, um einen reibungslosen Betrieb des Zentrums zu gewährleisten und dass andere Besucher beim bequemen Einkaufen nicht gestört oder belästigt werden. Im Falle eines Verstoßes gegen die Besucherordnung sind die SBS-Mitarbeiter berechtigt, gegen den Verletzer vorzugehen.
    
    1) Beschädigung oder Zerstörung von Eigentum von OC Hypka, Manipulation von technischen und technologischen Geräten und Durchführung von Aktivitäten, die die Sicherheit von Personen und Eigentum gefährden.
    2) Konsum von Alkohol (außer in dafür vorgesehenen Bereichen).
    3) Rauchen (außer in dafür vorgesehenen Bereichen).
    4) Besitz und Gebrauch von Drogen, psychotropen und anderen berauschenden Substanzen.
    5) Liegen oder Schlafen in den Räumlichkeiten des Gebäudes.
    6) Betteln oder anderweitiges Belästigen von Besuchern zum Zwecke der Geschenkerlangung.
    7) Mitbringen von Gegenständen, die die öffentliche Sicherheit, das Leben und die Gesundheit von Besuchern oder Mitarbeitern gefährden.
    8) Verschmutzung des Gebäudes, Spucken, Kaugummi kleben, Müll außerhalb der vorgesehenen Abfallbehälter hinterlassen usw.
    9) Fahren mit Rollschuhen, Skateboards, Rollern oder anderen sportlich-unterhaltenden Transportmitteln in den Räumlichkeiten des Einkaufszentrums.
    10) Stören oder anderweitiges Belästigen anderer Personen im Gebäude durch ihr Verhalten, ihre Kleidung, Lärm, Geruch, vulgäres oder unangemessenes Verhalten usw.
    11) Betreten von Bereichen, die für die Versorgung oder technische Wartung vorgesehen sind.
    12) Bewegung im Gebäude außerhalb der Öffnungszeiten des Zentrums, außer in den Teilen, die für die Öffentlichkeit uneingeschränkt zugänglich sind.
    13) Durchführung von kommerziellen Aktivitäten auf dem Gelände des Zentrums ohne Zustimmung der Verwaltung, Durchführung von Werbekampagnen, Produktwerbung, Verteilung von Flyern, Verkauf von Waren oder Dienstleistungen, Angebot von Proben, Durchführung von Umfragen und Umfragen, Betrieb von Musik- oder Tanzproduktionen, Aufstellen von Werbetafeln oder Ständern jeglicher Art.
    14) Organisation von politischen Agitationen, Petitionsaktionen, Umfragen zur öffentlichen Meinung, Demonstrationen, Werbeaktionen für jegliche Bewegungen und Durchführung von Finanzsammlungen. Eine Genehmigung kann nur von der Verwaltung des Zentrums erteilt werden, die auch berechtigt ist, einschränkende Maßnahmen festzulegen.
    15) Hunde ohne Leine/Beißkorb frei im Gebäude und auf angrenzenden Außenflächen laufen lassen.`
	},
	slug: {
		sk: 'navstevny-poriadok',
		en: 'visitor-policy',
		de: 'besucherrichtlinien'
	}
};

export default VisitorPolicy;
