const LoadingIcon = () => (
	<div className="loader-wrapper">
		<div className="lds-ring">
			<div />
			<div />
			<div />
			<div />
		</div>
	</div>
);

export default LoadingIcon;
