import { Button, styled } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import sorting from '../../lib/sorting';
import GridItem from '../cms/category/GridItem';
import useCms, { Article } from '../cms/useCms';
import Box from '../ui/box/Box';
import Grid from '../ui/grid/Grid';
import Typography from '../ui/typography/Typography';

const StyledComponent = styled(Box)(({ theme }) => ({
	'& .MuiTypography-h2': {
		marginBottom: theme.spacing(4)
	}
}));

const ShopsGridLogo = () => {
	const cms = useCms();
	const {
		t,
		i18n: { language }
	} = useTranslation();
	const navigate = useNavigate();
	//	const theme = useTheme();
	// const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		cms.getCategory('shops');
	}, []);

	return (
		<StyledComponent>
			<Typography variant="h2" textAlign="center" textTransform="uppercase">
				{t(`cms:category.shops.title`)}
			</Typography>

			<Grid container spacing={2} textAlign="center">
				{cms.category?.items &&
					sorting(cms.category?.items, 'order')
						.slice(0, 6)
						.map((item: Article) => (
							<Grid key={item.id} item xs={6} sm={3} lg={2}>
								<GridItem item={item} />
							</Grid>
						))}
			</Grid>
			<Box mt={4} textAlign="center">
				<Button
					variant="outlined"
					size="large"
					onClick={() => navigate(`/${language}/shops`)}
				>
					{t('labels:more')}
				</Button>
			</Box>
		</StyledComponent>
	);
};

export default ShopsGridLogo;
