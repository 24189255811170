import { IContent } from '../content';

const BusinessHours: IContent = {
	title: {
		sk: `Otváracie hodiny`,
		en: `Opening hours`,
		de: `Öffnungszeiten`
	},
	content: {
		sk: ``,
		en: ``,
		de: ``
	},
	slug: {
		sk: 'otvaracie-hodiny',
		en: 'opening-hours',
		de: 'offnungszeiten'
	}
};

export default BusinessHours;
