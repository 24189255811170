import '@fontsource/montserrat';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';
import '@fontsource/open-sans';

import '@fontsource/roboto';
import '@fontsource/roboto/400.css';

import MuiPalette from './palette';

const typography = {
	useNextVariants: true,
	suppressDeprecationWarnings: true,
	h1: {
		fontFamily: 'Montserrat',
		color: MuiPalette?.text?.primary,
		fontSize: 34,
		lineHeight: 1.5,
		fontWeight: 700,
		letterSpacing: -1
	},
	h2: {
		fontFamily: 'Montserrat',
		color: MuiPalette?.text?.primary,
		fontSize: 28,
		lineHeight: 1.3,
		fontWeight: 700
	},
	h3: {
		fontFamily: 'Montserrat',
		color: MuiPalette?.text?.primary,
		fontSize: 20,
		lineHeight: 1.3,
		fontWeight: 500,
		letterSpacing: '0px'
	},
	h4: {
		fontFamily: 'Montserrat',
		color: MuiPalette?.text?.primary,
		fontSize: 18,
		lineHeight: 1.3,
		fontWeight: 600
	},
	h5: {
		fontFamily: 'Montserrat',
		color: MuiPalette?.text?.primary,
		fontSize: 16,
		lineHeight: 1.3,
		fontWeight: 700
	},
	body1: {
		fontSize: 15,
		fontFamily: 'Montserrat',
		fontWeight: 500,
		'white-space': 'pre-line'
	},
	body2: {
		fontSize: 15,
		fontFamily: 'Montserrat',
		fontWeight: 500,
		'white-space': 'pre-line',
		letterSpacing: '-0.25px'
	},
	caption: {
		fontSize: 17,
		fontFamily: 'Montserrat',
		fontWeight: 700,
		opacity: 0.99,
		letterSpacing: '-0.2px'
	},
	subtitle1: {
		fontSize: 24,
		lineHeight: 1.2,
		fontFamily: 'Montserrat',
		fontWeight: 500,
		opacity: 0.7,
		letterSpacing: '-1px'
	},
	subtitle2: {
		fontSize: 17,
		lineHeight: 1.2,
		fontFamily: 'Montserrat',
		fontWeight: 700,
		letterSpacing: '-0,25px'
	}
};

export default typography;
