import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import useApp from '../app/useApp';

interface Props {
	title: string;
	description: string;
	name?: string;
	type?: string;
}

const Seo = ({ title, description, name, type }: Props) => {
	const { app } = useApp();
	const { i18n } = useTranslation();

	return (
		<Helmet htmlAttributes={{ lang: i18n.language }}>
			{/* Standard metadata tags */}
			<title>
				{title} | {app.company} Bardejov
			</title>
			<meta name="description" content={description} />
			<meta name="robots" content="index, follow" />
			<meta
				name="keywords"
				content="Obchodné centrum, hypka, Bardejov, Lidl, Pepco, Dr.Max, Parkovisko, "
			/>
			<link rel="canonical" href={window.location.origin} />
			<meta name="author" content="WEBLAB CONSULTING LTD." />
			<meta httpEquiv="Content-Language" content={i18n.language} />

			{/* Facebook tags */}
			<meta property="og:type" content={type} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />

			{/* Twitter tags */}
			<meta name="twitter:creator" content={name} />
			<meta name="twitter:card" content={type} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
		</Helmet>
	);
};

export default Seo;
