import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from '../../ui/box/Box';
import Typography from '../../ui/typography/Typography';
import { Article } from '../useCms';

interface Props {
	item: Article;
	showDescription?: boolean;
}

const GridItem = ({ item, showDescription = false }: Props) => {
	const navigate = useNavigate();
	const {
		i18n: { language }
	} = useTranslation();

	return (
		<Box
			onClick={() => navigate(`/${language}${item.to}`)}
			sx={{ cursor: 'pointer' }}
		>
			<Paper variant="outlined" sx={{ textAlign: 'center', p: 1 }}>
				<img alt="Logo" title="Logo" src={item.logo} width="100%" />
			</Paper>
			<Box>
				<Box pt={2} px={1}>
					<Typography variant="caption" component="div" lineHeight={1.4} mb={1}>
						{item.title}
					</Typography>
					{showDescription && (
						<Typography variant="body1" lineHeight={1.2}>
							{item.description}
						</Typography>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default GridItem;
