import { IContent } from '../content';

const Career: IContent = {
	title: {
		sk: `Cookie`,
		en: `Cookie`,
		de: `Cookie`
	},
	/* 	description: {
		sk: 'GDPR interné smernice spoločnosti',
		en: 'GDPR internal company directives',
		de: 'GDPR-interne Unternehmensrichtlinien'
	}, */
	content: {
		sk: `
		Dátum účinnosti: 30. novembra 2023
Naposledy aktualizované: 30. novembra 2023

<b>Čo sú to cookies?</b>

Táto politika cookies vysvetľuje, čo sú cookies a ako ich používame, aké typy cookies používame, t.j. informácie, ktoré zbierame pomocou cookies a ako sú tieto informácie použité, a ako spravovať nastavenia cookies. 

Cookies sú malé textové súbory, ktoré sa používajú na ukladanie malých kúskov informácií. Ukladajú sa na vašom zariadení, keď je webová stránka načítaná vo vašom prehliadači. Tieto cookies nám pomáhajú zabezpečiť správne fungovanie webovej stránky, zvýšiť jej bezpečnosť, poskytnúť lepší používateľský zážitok a pochopiť, ako webová stránka funguje, a analyzovať, čo funguje a kde je potrebné zlepšenie.

<b>Ako používame cookies?</b>

Ako väčšina online služieb, naša webová stránka používa cookies prvej a tretej strany na rôzne účely. Cookies prvej strany sú prevažne nevyhnutné pre správne fungovanie webovej stránky a nezbierajú žiadne vaše osobné údaje.

Cookies tretej strany, ktoré používame na našej webovej stránke, sú hlavne na pochopenie, ako webová stránka funguje, ako s ňou interagujete, na udržanie našich služieb bezpečnými, poskytovanie reklám, ktoré sú pre vás relevantné, a celkovo na poskytnutie lepšieho a zdokonaleného používateľského zážitku a na urýchlenie vašich budúcich interakcií s našou webovou stránkou.

<b>Typy cookies, ktoré používame</b>
<div class="cky-audit-table-element"></div>

<b>Spravovať preferencie cookies</b>

<a class="cky-banner-element">Cookie Settings</a> 
Nastavenia cookies Môžete kedykoľvek zmeniť svoje preferencie cookies kliknutím na tlačidlo vyššie. Tým sa môžete vrátiť k súhlasnému banneru cookies a zmeniť svoje preferencie alebo okamžite odvolať svoj súhlas.

Okrem toho rôzne prehliadače poskytujú rôzne metódy blokovania a mazania cookies používaných webovými stránkami. Môžete zmeniť nastavenia svojho prehliadača na blokovanie/mazanie cookies. Nižšie sú uvedené odkazy na podporné dokumenty o tom, ako spravovať a mazať cookies z hlavných webových prehliadačov.

Chrome: https://support.google.com/accounts/answer/32050
Safari: https://support.apple.com/en-in/guide/safari/sfri11471/mac
Firefox: https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US
Internet Explorer: https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc

Ak používate iný webový prehliadač, navštívte prosím oficiálne podporné dokumenty vášho prehliadača.   
		`,
		en: `
		Effective Date: 30-Nov-2023 
		Last Updated: 30-Nov-2023 

		<b>What are cookies?</b>
		
		This Cookie Policy explains what cookies are and how we use them, the types of cookies we use i.e, the information we collect using cookies and how that information is used, and how to manage the cookie settings.
		
		Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.
		
		<b>How do we use cookies?</b>
		
		As most of the online services, our website uses first-party and third-party cookies for several purposes. First-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data.
		
		The third-party cookies used on our website are mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website.
		
		<b>Types of Cookies we use</b>
		<div class="cky-audit-table-element"></div>

		<b>Manage cookie preferences</b>

		<a class="cky-banner-element">Cookie Settings</a> 
		Cookie Settings You can change your cookie preferences any time by clicking the above button. This will let you revisit the cookie consent banner and change your preferences or withdraw your consent right away. 
		
		In addition to this, different browsers provide different methods to block and delete cookies used by websites. You can change the settings of your browser to block/delete the cookies. Listed below are the links to the support documents on how to manage and delete cookies from the major web browsers.
		
		Chrome: https://support.google.com/accounts/answer/32050
	   	Safari: https://support.apple.com/en-in/guide/safari/sfri11471/mac
	   	Firefox: https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US
	   	Internet Explorer: https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc
	   	
		If you are using any other web browser, please visit your browser’s official support documents.
	   `,
		de: `
		Wirksamkeitsdatum: 30. November 2023
Zuletzt aktualisiert: 30. November 2023

<b>Was sind Cookies?</b>

Diese Cookie-Richtlinie erklärt, was Cookies sind und wie wir sie verwenden, die Arten von Cookies, die wir verwenden, d.h. die Informationen, die wir mithilfe von Cookies sammeln und wie diese Informationen verwendet werden, und wie Sie die Cookie-Einstellungen verwalten können.

Cookies sind kleine Textdateien, die verwendet werden, um kleine Informationsstücke zu speichern. Sie werden auf Ihrem Gerät gespeichert, wenn die Website in Ihrem Browser geladen wird. Diese Cookies helfen uns, die Website ordnungsgemäß funktionieren zu lassen, sie sicherer zu machen, ein besseres Benutzererlebnis zu bieten und zu verstehen, wie die Website funktioniert und zu analysieren, was funktioniert und wo Verbesserungen nötig sind.

<b>Wie verwenden wir Cookies?</b>

Wie die meisten Online-Dienste verwendet unsere Website Cookies von Erst- und Drittanbietern zu verschiedenen Zwecken. Erstanbieter-Cookies sind meist notwendig, damit die Website richtig funktioniert, und sie sammeln keine Ihrer persönlich identifizierbaren Daten.

Die von unserer Website verwendeten Cookies von Drittanbietern dienen hauptsächlich dazu, zu verstehen, wie die Website funktioniert, wie Sie mit unserer Website interagieren, unsere Dienste sicher zu halten, Ihnen relevante Werbung bereitzustellen und insgesamt ein besseres und verbessertes Benutzererlebnis zu bieten und Ihre zukünftigen Interaktionen mit unserer Website zu beschleunigen.

<b>Arten von Cookies, die wir verwenden</b>


<b>Cookie-Einstellungen verwalten</b>

<a class="cky-banner-element">Cookie Settings</a> 
Sie können Ihre Cookie-Einstellungen jederzeit ändern, indem Sie auf die obige Schaltfläche klicken. Dies ermöglicht es Ihnen, das Cookie-Zustimmungsbanner erneut zu besuchen und Ihre Einstellungen zu ändern oder Ihre Zustimmung sofort zu widerrufen.

Darüber hinaus bieten verschiedene Browser unterschiedliche Methoden zum Blockieren und Löschen von Cookies, die von Websites verwendet werden. Sie können die Einstellungen Ihres Browsers ändern, um Cookies zu blockieren/löschen. Unten sind die Links zu den Support-Dokumenten aufgeführt, wie Sie Cookies von den wichtigsten Webbrowsern verwalten und löschen können.

Chrome: https://support.google.com/accounts/answer/32050
Safari: https://support.apple.com/en-in/guide/safari/sfri11471/mac
Firefox: https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US
Internet Explorer: https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc

Wenn Sie einen anderen Webbrowser verwenden, besuchen Sie bitte die offiziellen Support-Dokumente Ihres Browsers.
	   `
	},
	slug: {
		sk: 'cookie',
		en: 'cookie',
		de: 'cookie'
	}
};

export default Career;
