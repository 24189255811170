import { styled, useTheme } from '@mui/material';
import Box from '../ui/box/Box';
import Grid from '../ui/grid/Grid';
import useContact from './useContact';

const StyledComponent = styled(Box)(({ theme }) => ({
	'& .MuiTypography-h3': {
		fontSize: 32,
		fontWeight: 700,
		marginBottom: theme.spacing(4)
	},
	'& .MuiTypography-body1': {
		fontSize: 20,
		fontWeight: 700,
		marginBottom: theme.spacing(0)
	}
}));

const GoogleMap = () => {
	const theme = useTheme();
	const { contact } = useContact();

	if (!contact) return null;

	return (
		<StyledComponent>
			<Grid container spacing={6} mb={6}>
				<Grid item xs={12} height={500}>
					<iframe
						width="100%"
						height="100%"
						frameBorder="0"
						title="map"
						marginHeight={0}
						marginWidth={0}
						scrolling="no"
						src={contact.map}
						style={{
							borderRadius: 20,
							filter:
								theme.palette.mode === 'dark'
									? 'grayscale(0.5) opacity(0.7)'
									: 'none'
						}}
					/>
				</Grid>
			</Grid>
		</StyledComponent>
	);
};

export default GoogleMap;
