import MenuIcon from '@mui/icons-material/Menu';
import {
	Box,
	IconButton,
	List,
	ListItemButton,
	ListItemText
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import useCms from '../cms/useCms';
import Logo from '../logo/Logo';
import LangSwitcher from '../translation/LangSwitcher';
import { NAVIGATION_LIST, TypeNavigation } from './Navigation';

type Anchor = 'left' | 'right' | 'bottom';

const Position: Anchor = 'right';

const MobileNavigation = () => {
	const [isOpen, setIsOpen] = useState(false);
	const location = useLocation();
	const cms = useCms();
	const list = cms.getNavigationList(NAVIGATION_LIST);
	const isSelected = list.find((nav) => nav.to === location.pathname);
	const {
		t,
		i18n: { language }
	} = useTranslation();

	const toggleDrawer =
		(_anchor: Anchor, open: boolean) =>
		(event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}

			setIsOpen(open);
		};

	return (
		<>
			<IconButton onClick={toggleDrawer(Position, true)}>
				<MenuIcon />
			</IconButton>
			<Drawer
				anchor={Position}
				open={isOpen}
				onClose={toggleDrawer(Position, false)}
			>
				<List onClick={toggleDrawer(Position, false)}>
					<ListItemButton component={Link} to="/" sx={{ mx: 4 }}>
						<Logo />
					</ListItemButton>
					{list.map((item: TypeNavigation) => (
						<ListItemButton
							key={item.id}
							divider
							selected={isSelected?.to === item.to ?? false}
							component={Link}
							to={`/${language}${item.to}`}
						>
							<ListItemText primary={t(item.title)} sx={{ px: 2 }} />
						</ListItemButton>
					))}
				</List>
				<Box mx="auto" mt={2} display="flex">
					<LangSwitcher />
				</Box>
			</Drawer>
		</>
	);
};

export default MobileNavigation;
