import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useCms from '../cms/useCms';
import Link from '../ui/link/Link';

export const NAVIGATION_LIST: string[] = [
	'a3310469-6001-4cfb-a26a-8f57767655d2',
	'a3310469-6001-4cfb-a26a-8f57767655d3',
	'bed52a6a-c44c-47c0-b61a-6660012dfd38',

	'1de1c447-cf32-4f62-89fd-8f0dac7d1d73'
];
export interface TypeNavigation {
	title: string;
	id: string | number;
	slug: string;
	to: string;
}

const Navigation = () => {
	const location = useLocation();
	const cms = useCms();
	const list = cms.getNavigationList(NAVIGATION_LIST);
	const {
		t,
		i18n: { language }
	} = useTranslation();
	const isSelected = list.find((nav) => nav.to === location.pathname);

	return (
		<Stack my={2} direction="row" gap={3}>
			{list.map((item: TypeNavigation) => (
				<Link
					key={item.id}
					to={`/${language}${item.to}`}
					title={t(item.title)}
					selected={isSelected?.to === item.to ?? false}
					style={{
						fontSize: 18,
						fontWeight: 700,
						fontFamily: 'Montserrat'
					}}
				>
					{t(item.title)}
				</Link>
			))}
		</Stack>
	);
};

export default Navigation;
