import React from 'react';
import 'yet-another-react-lightbox/styles.css';
import Lightbox, { SlideImage } from 'yet-another-react-lightbox';
import Inline from 'yet-another-react-lightbox/plugins/inline';

interface Props {
	slides: SlideImage[];
}

const CarouselComponent = ({ slides }: Props) => {
	const [open, setOpen] = React.useState(false);
	const [index, setIndex] = React.useState(0);
	const updateIndex = ({ index: current }: { index: number }) =>
		setIndex(current);

	const toggleOpen = (state: boolean) => () => setOpen(state);

	return (
		<>
			<Lightbox
				index={index}
				slides={slides}
				plugins={[Inline]}
				on={{
					view: updateIndex,
					click: toggleOpen(true)
				}}
				carousel={{
					padding: 0,
					spacing: 0,
					imageFit: 'cover'
				}}
				inline={{
					style: {
						width: '100%',
						maxWidth: '900px',
						aspectRatio: '3 / 2',
						margin: '0 auto'
					}
				}}
			/>

			<Lightbox
				open={open}
				close={toggleOpen(false)}
				index={index}
				slides={slides}
				on={{ view: updateIndex }}
				animation={{ fade: 0 }}
				controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
			/>
		</>
	);
};

export default CarouselComponent;
