import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/system';

import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import i18next from './i18n/config';
import store from './lib/store';
import LangRouter from './routes/langRouter/LangRouter';

import theme from './theme/theme';

const App = () => (
	<Provider store={store}>
		<CssBaseline />
		<HelmetProvider>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<I18nextProvider i18n={i18next}>
						<LangRouter />
					</I18nextProvider>
				</BrowserRouter>
			</ThemeProvider>
		</HelmetProvider>
	</Provider>
);

export default App;
