import sorting from '../../../lib/sorting';
import Box from '../../ui/box/Box';

import Grid from '../../ui/grid/Grid';
import { Article, Category } from '../useCms';
import GridItem from './GridItem';

interface Props {
	children: Category['items'];
}

const GridList = ({ children }: Props) => (
	<Box minHeight={600}>
		{!!children?.length && (
			<Grid container spacing={2} mt={0}>
				{sorting(children, 'order').map((item: Article) => (
					<Grid key={item.id} item xs={6} sm={3} md={3} lg={2.4}>
						<GridItem item={item} showDescription />
					</Grid>
				))}
			</Grid>
		)}
	</Box>
);

export default GridList;
