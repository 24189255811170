import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SlideImage } from 'yet-another-react-lightbox';
import React from 'react';
import Typography from '@mui/material/Typography';
import { RentingSpacesTableData } from '../../../codelist/_content/RentingSpaces';
import { RentingSpacesTablesDataFragment } from '../../../codelist/content';
import Carousel from '../../carousel/Carousel';

const RentingSpacesTable = () => {
	const {
		i18n: { language }
	} = useTranslation();

	const slides: Record<number, SlideImage[]> = {
		0: [
			{ src: '/assets/renting_spaces/kontainer01.webp', width: 800 },
			{ src: '/assets/renting_spaces/kontainer02.webp', width: 800 },
			{ src: '/assets/renting_spaces/kontainer03.webp', width: 800 }
		],
		1: [
			{ src: '/assets/renting_spaces/office01.webp', width: 800 },
			{ src: '/assets/renting_spaces/office02.webp', width: 800 },
			{ src: '/assets/renting_spaces/office03.webp', width: 800 }
		],
		2: [
			{ src: '/assets/renting_spaces/space01.webp', width: 800 },
			{ src: '/assets/renting_spaces/space02.webp', width: 800 }
		]
	};

	if (!RentingSpacesTableData.length) return null;

	return (
		<>
			{RentingSpacesTableData.map(
				(row: RentingSpacesTablesDataFragment, index) => (
					<Grid container spacing={0} key={row.title[language]}>
						<Grid item xs={12} md={4}>
							<Carousel slides={slides[index]} />
						</Grid>
						<Grid item xs={12} md={8}>
							<Box ml={4}>
								<Typography variant="h3">{row.title[language]}</Typography>
								<Typography>{row.meters} m²</Typography>
								<Typography>{row.description[language]}</Typography>
							</Box>
						</Grid>
					</Grid>
				)
			)}
		</>
	);
};

export default RentingSpacesTable;
