import Typography from '../../ui/typography/Typography';

interface Props {
	data?: string;
}

const Address = ({ data }: Props) => {
	//  const { t } = useTranslation();

	if (!data) return null;

	return (
		<>
			<Typography variant="caption" component="div" mb={1}>
				Adresa
			</Typography>
			<Typography variant="body1">{data}</Typography>
		</>
	);
};

export default Address;
