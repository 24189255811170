import useTranslate from '../translation/useTranslate';
import Divider from '../ui/divider/Divider';
import Typography from '../ui/typography/Typography';

const OpeningHours = () => {
	const t = useTranslate('openingHours');
	return (
		<>
			<Typography variant="h3" letterSpacing={1} textTransform="uppercase">
				{t('title')}
			</Typography>
			<Divider sx={{ my: 2 }} />
			<Typography>
				{t('weekdays')}: <strong>07 - 21</strong>
			</Typography>
			<Typography>
				{t('weekend')}: <strong>08 - 20</strong>
			</Typography>
		</>
	);
};

export default OpeningHours;
