import { useTranslation } from 'react-i18next';
import { THEME } from '../../theme/theme';
import HomeBanner from '../banner/HomeBanner';
import ContactMap from '../contact/ContactMap';
import PartnersGridLogo from '../gridLogo/PartnersGridLogo';
import ShopsGridLogo from '../gridLogo/ShopsGridLogo';
import Seo from '../seo/Seo';
import Box from '../ui/box/Box';
import Container from '../ui/container/Container';
import Divider from '../ui/divider/Divider';
import WelcomeContent from './WelcomeContent';

const HomePage = () => {
	const { t } = useTranslation();
	return (
		<Container maxWidth={THEME.maxWidth}>
			<Seo title={t('home:title')} description={t('home:description')} />
			<HomeBanner />
			<Box mt={3} />
			<WelcomeContent />
			<Divider sx={{ my: 6 }} />
			<ShopsGridLogo />
			<Divider sx={{ my: 6 }} />
			<PartnersGridLogo />
			<Divider sx={{ my: 6 }} />
			<ContactMap />
		</Container>
	);
};

export default HomePage;
